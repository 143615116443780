<template>
  <div class="grid grid-cols-[5rem_1fr] sm:grid-cols-[6rem_1fr] gap-2 tile">
    <div class="aspect-[5/7] relative row-span-3">
      <AsyncImage
        :src="coverUrl"
        class="absolute top-0 h-full w-full left-0 object-cover rounded shadow"
      />
    </div>
    <div class="space-y-2 min-w-0">
      <h1 class="text-xl font-bold truncate">
        {{ titleTitle }}
      </h1>
      <TitleStats v-if="!noStats" :titleId="id" :small="small || !sm" />
      <div
        v-if="!noOrg"
        class="py-1 rounded-full whitespace-nowrap flex items-center space-x-1"
      >
        <IconBuilding class="flex-shrink-0 text-nami-nami-blue" :size="20" />
        <div v-for="org in organizations" class="px-1 text-sm font-medium">
          {{ org.attributes?.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Title, type TitleEntity } from "~/src/api";
import { parseTitleForDisplay } from "~/utils/display/title";
import {
  IconBookmark,
  IconBuilding,
  IconEye,
  IconMessageDots,
} from "@tabler/icons-vue";

interface Props {
  title?: TitleEntity;
  noStats?: boolean;
  noOrg?: boolean;
  small?: boolean;
}

const props = defineProps<Props>();
const { sm } = useBreakpoints();

const {
  coverUrl,
  title: titleTitle,
  organizations,
  id,
} = parseTitleForDisplay(computed(() => props.title));
</script>

<style scoped lang="postcss"></style>
